import Services from './services'

class AutenticarClienteServices extends Services {
    constructor() {
        super({
            url: '/autenticar-cliente',
            config: {
                baseURL: process.env.VUE_APP_API_CREDITO_URL,
                headers: {
                    authorization: `JWT ${process.env.VUE_APP_API_CREDITO_TOKEN}`
                },
                timeout: 60 * 1000
            }
        })
    }
}

export default new AutenticarClienteServices()
