export default [
    {
        id: 'mnPagamentos',
        path: '/pagamentos',
        name: 'Pagamentos',
        icon: 'payments',
        hidden: false,
        component: () => import('@/views/PagamentosParcelas'),
        meta: {
            auth: true,
            title: 'Pagamentos'
        }
    }
]
