import date from 'quasar/src/utils/date/date.js';;
import VueCookies from "vue-cookies";

const ESTRUTURA_USUARIO = {
  id: -1,
  codigo: null,
  id_loja: null,
  id_cartao: null,
  nome: "",
  sexo: "",
  cpf: "",
  email: "",
  nascimento: null,
  data_cadastro: null,
  telefone_celular: "",
  id_dependente: null,
  criar_senha: false,
  modo_noturno: false,
  recaptcha_token: null,
};

export default {
  state: {
    usuario: ESTRUTURA_USUARIO,
    validadeLogin: 0,
  },

  getters: {
    getUsuarioLogado: (state) => state.usuario,

    isUsuarioAutenticado: (state) => {
      if (!state.validadeLogin) {
        return false;
      }
      if (date.getDateDiff(new Date(), state.validadeLogin, "minutes") > 30) {
        state.validadeLogin = 0;
        state.usuario = ESTRUTURA_USUARIO;
        return false;
      }
      return true;
    },
  },

  mutations: {
    login(state, dadosUsuario) {
      state.usuario = dadosUsuario;
      state.validadeLogin = date.addToDate(new Date(), { minutes: 30 });
    },

    logout(state) {
      VueCookies.remove("CUSTOMER_TOKEN_CLIENTE");
      state.validadeLogin = 0;
      state.usuario = ESTRUTURA_USUARIO;
    },
  },
};
