export default [
    {
        id: 'mnNovoPagamento',
        path: '/novo-pagamento',
        name: 'novoPagamento',
        hidden: true,
        component: () => import('@/views/Boleto'),
        isHeader: false,
        meta: {
            auth: true,
            title: 'Novo Pagamento'
        }
    }
]
