export default [
  {
    id: "mnHome",
    path: "/home",
    name: "Home",
    icon: "widgets",
    hidden: true,
    component: () => import("@/views/Home"),
    meta: {
      title: "Home",
      auth: true,
      noBack: true
    },
  },
];
