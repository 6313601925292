<template>
    <div id="app">
        <RouterView />
    </div>
</template>

<script>
export default {
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(to) {
                let title = `Cartão ${process.env.VUE_APP_DESCRICAO_EMPRESA}`
                title = to.meta.title ? `${title} | ${to.meta.title}` : title
                document.title = title
            },
        },
    },
};
</script>
