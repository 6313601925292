import * as Sentry from "@sentry/vue";

export default function initializeSentry(app, router) {
    Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_SENTRY_ENV,
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
              "localhost",
              "https://cartao-sandbox.lojaspompeia.com.br",
              "https://cartao-sandbox.gang.com.br",
              "https://cartao.lojaspompeia.com.br/",
              "https://cartao.gang.com.br/",
            ],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: Number(process.env.SENTRY_RATE_TRACES), // Capture 100% of the transactions, reduce in production!
        profilesSampleRate: Number(process.env.SENTRY_RATE_PROFILES),
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
}