import Services from './services'
import VueCookies from 'vue-cookies'

export default class ServicesAuth extends Services {
    constructor({ url = '', config = {} }) {
        super({ url, config })

        this.axios.interceptors.request.use(
            function(config) {
                // Do something before request is sent

                config.headers = {
                    ...config.headers,
                    'customer-token': VueCookies.get('CUSTOMER_TOKEN_CLIENTE')
                }

                return config
            },
            function(error) {
                // Do something with request error
                return Promise.reject(error)
            }
        )

        this.axios.interceptors.response.use(
            function(response) {
                // Do something before response data
                return response
            },
            function(error) {
                // Do something with response error
                return Promise.reject(error)
            }
        )
    }
}
