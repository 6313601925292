import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes'
import Loading from 'quasar/src/plugins/loading/Loading.js';
import login from '../plugins/login'

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.afterEach(() => {
  Loading.hide()
})

router.beforeEach((to, from, next) => {
  if ((!to.meta.auth) || (login.isAutenticado())) {
    Loading.show()
    next()
  } else {
    next({ path: '/login' })
  }
})

export default router
