import '@/styles/quasar.scss'
import setCssVar from 'quasar/src/utils/css-var/set-css-var.js';import Notify from 'quasar/src/plugins/notify/Notify.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';

import lang from 'quasar/lang/pt-BR.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'

if (process.env.VUE_APP_EMPRESA.toLowerCase() === 'gang') {
  setCssVar('primary', '#001432')
  setCssVar('secondary', '#0055E1')
  setCssVar('accent', '#F89E41')
}

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify,
    Loading,
    Dialog
  },
  lang: lang
}