export default [
    {
        id: 'mnClientePage',
        path: '/cliente',
        name: 'Cliente',
        icon: 'person',
        hidden: true,
        component: () => import('@/views/manutencaoCliente'),
        meta: {
            auth: true,
            title: 'Manutenção do cliente'
        }
    }
]
