export default [
  {
    id: "mnMeuBonus",
    path: "/meu-bonus",
    name: "Meu Bônus",
    icon: "currency_exchange",
    hidden: false,
    component: () => import("@/views/MeuBonus"),
    isHeader: true,
    meta: {
      auth: true,
      title: "Meu Bônus",
    },
  },
];
