import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import Auth from './modules/auth.js'

const ls = new SecureLS({ isCompression: false })

export default new Vuex.Store({
    namespaced: true,

    modules: {
        auth: Auth
    },

    state: {
    },

    mutations: {
    },

    actions: {
    },

    plugins: [
        createPersistedState({
            key: 'app-area-cliente',

            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key)
            }
        })
    ]
})
