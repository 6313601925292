import Router from "@/router/index";
import store from "@/store/index";

import AutenticarClienteService from "@/services/autenticar_cliente";
import ClienteService from "@/services/cliente";

import VueCookies from "vue-cookies";

class ClassLogin {
  idCartao = null;

  isAutenticado() {
    return store.getters.isUsuarioAutenticado;
  }

  usuarioLogado() {
    return store.getters.getUsuarioLogado;
  }

  login(cpf, nascimento, recaptcha) {
    return new Promise((resolve, reject) => {
      AutenticarClienteService.get({ cpf, nascimento, recaptcha })
        .then(({ data }) => {
          VueCookies.set("CUSTOMER_TOKEN_CLIENTE", data.customer_token);
          this.idCartao = data.id_cartao;
          const criarSenha = Boolean(data.criar_senha);
          const idDependente = data.id_dependente;
          ClienteService.getByID(data.id_cliente, {
            fields:
              "id,codigo,id_loja,nome,sexo,cpf,email,nascimento,data_cadastro,telefone_celular",
          }).then(({ data }) => {
            data.id_cartao = this.idCartao;
            data.id_dependente = idDependente;
            data.criar_senha = criarSenha;
            data.modo_noturno = false;
            data.recaptcha_token = recaptcha;
            store.commit("login", data);
            resolve(true);
          });
        })
        .catch((error) => {
          this.logout();
          try {
            reject(
              error.response.data.data[
                Object.keys(error.response.data.data)[0]
              ][0]
            );
          } catch (e) {
            reject(error);
          }
        });
    });
  }

  logout() {
    Router.push("/login");
    store.commit("logout");
  }
}

const login = new ClassLogin();

export default login;
