export default [
    {
        id: 'mnSolicitacaoSenha',
        path: '/solicitacao-senha',
        name: 'Solicitar Senha',
        hidden: false,
        desabilitaDependente: true,
        icon: 'https',
        component: () => import('@/views/SolicitacaoSenha'),
        meta: {
            auth: true,
            title: 'Solicitar Senha'
        }
    }
]
