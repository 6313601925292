export default [
    {
        id: 'mnCartaoVirtual',
        path: '/cartao-virtual',
        name: 'Cartão Virtual',
        hidden: false,
        icon: 'credit_card',
        component: () => import('@/views/Cartao'),
        meta: {
            auth: true,
            title: 'Cartão Virtual'
        }
    }
]
