/* eslint-disable no-console */

import { register } from "register-service-worker";
import Notify from 'quasar/src/plugins/notify/Notify.js';;

register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log(
      "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
    );
  },
  registered() {
    console.log("Service worker has been registered.");
  },
  cached() {
    console.log("Content has been cached for offline use.");
  },
  updatefound() {
    console.log("New content is downloading.");
  },
  updated(registration) {
    Notify.create({
      message: "A aplicação está sendo atualizada!<br> Por favor, Aguarde...",
      icon: "refresh",
      position: "top-right",
      type: "positive",
      html: true,
      onDismiss() {
        registration.update();
        location.reload(true);
      },
    });
    console.log("Novo conteúdo está disponível. Aplicação será atualizada");
  },
  offline() {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  },
});