<template>
    <q-dialog
        id="modUsuarioInativo"
        ref="modal"
        persistent
        :maximized="mxScreen_IsLowResolution"
    >
        <q-card>
            <q-card-section class="bg-primary text-white">
                <div class="text-h6 no-margin no-padding">Usuário Inativo</div>
            </q-card-section>
            <q-card-section>
                <div class="text-h6">Olá! Você ainda esta ai? 😊</div>
                <div class="text-subtitle2">Apenas queria lembrar que você será desconectado automaticamente em: {{String(tempoModalInatividade).padStart(2, '0')}}</div>
            </q-card-section>
            <q-card-actions>
                <q-btn
                    class="full-width q-ma-sm"
                    color="positive"
                    label="Estou aqui"
                    @click="$_confirmar"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import mixinInatividade from '../../mixins/mixinInatividade'
import { mixinScreen } from 'lins-mixins'

export default {
    name: 'QUsuarioInativo',

    mixins: [
        mixinInatividade,
        mixinScreen
    ],

    data(){
        return {
            timerModal: {},
            tempoModalInatividade: Number(process.env.VUE_APP_TEMPO_VISUALIZACAO_MODAL_INATIVIDADE)
        }
    },

    methods: {
        $_confirmar(){
            this.$refs.modal.hide()
            this.$validarUsuario({usuarioInativo:false, isModal:true, chamaReset: true})
            this.$_limpar()
        },

        close() {
            this.$_limpar()
            this.$refs.modal.hide()
        },

        open() {
            clearInterval(this.timerModal)
            this.$refs.modal.show()
            this.$_iniciarContadorTempo()
        },

        $_iniciarContadorTempo() {
            this.timerModal = setInterval(() =>{
                this.tempoModalInatividade--
                if(this.tempoModalInatividade <= 0){
                    clearInterval(this.timerModal)
                    clearTimeout(this.timerMixin)
                    this.close()
                    this.$logout()
                }
            }, 1000)
        },

        $_limpar(){
            this.tempoModalInatividade = Number(process.env.VUE_APP_TEMPO_VISUALIZACAO_MODAL_INATIVIDADE)
            clearInterval(this.timerModal)
            this.timerModal = null
        },
    }
}
</script>

<style lang="scss">
.c-container-usuario-inativo {
    width: 15%;
    padding-left: 1%;
    padding-top: 15px;

    .grpAcoes {
        margin-bottom: 20px;
    }

    .label {
        padding: 1px 0px;
    }

    .input {
        color: rgb(78, 78, 78);
    }

    .buttons-margin {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .message-margin {
        margin-top: 20px;
        margin-bottom: -10px;
    }
}
</style>
