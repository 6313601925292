import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasar from "./plugins/quasar";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuex from "vuex";
import VueClipboard from "vue3-clipboard";
import VueBarcode from "@chenfengyuan/vue-barcode";
import GlfEmailDomainSuggestion from "glf-email-domain-suggestion";
import initializeSentry from "./plugins/sentry";
import ModalInatividade from "./components/shared/QModalUsuarioInativo";
import VueGtag from "vue-gtag";
import VueCookies from "vue-cookies";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import "./registerServiceWorker";

const app = createApp(App);

initializeSentry(app, router);

app
  .use(Quasar, quasar)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(Vuex)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .component(VueBarcode.name, VueBarcode)
  .component("QModalUsuarioInativo", ModalInatividade)
  .use(GlfEmailDomainSuggestion)
  .use(
    VueGtag,
    {
      appName: "area-cliente",
      pageTrackerScreenviewEnabled: true,
      config: { id: process.env.VUE_APP_GTAG },
    },
    router
  )
  .use(VueCookies, {
    expires: "1h",
    path: "/",
    secure: true,
    sameSite: "Lax",
  })
  .use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY })
  .mount("#app");
