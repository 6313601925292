import ServicesAuth from './servicesAuth'

class ClienteServices extends ServicesAuth {
    constructor() {
        super({
            url: '/clientes',
            config: {
                baseURL: process.env.VUE_APP_API_CREDITO_URL,
                headers: {
                    authorization: `JWT ${process.env.VUE_APP_API_CREDITO_TOKEN}`
                },
                timeout: 60 * 1000
            }
        })
    }

    getLimite(id) {
        return this.axios.get(`${this.url}/${id}/limite-credito`, { params: { app_origem: 'app_area_cliente' } })
    }

    getCliente(id, fields = null) {
        return this.axios.get(`${this.url}/${id}?fields=${fields}`)
    }
}

export default new ClienteServices()
