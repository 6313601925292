export default [
    {
        id: 'mnContrato',
        path: '/contrato',
        name: 'Contrato',
        hidden: false,
        icon: 'assignment',
        component: () => import('@/views/Contrato'),
        meta: {
            auth: true,
            title: 'Contrato'
        }
    }
]
