export default [
    {
        id: 'mnSelecaoParcelas',
        path: '/selecao-parcelas',
        name: 'selecaoParcelas',
        hidden: true,
        component: () => import('@/views/SelecaoParcelas'),
        isHeader: false,
        meta: {
            auth: true,
            title: 'Selecione as parcelas'
        }
    }
]
